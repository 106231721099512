import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom'
import Navbar from './Navbar';
import Footer from './Footer';
import '../css/HyperLeague.css';
import Logo from '../img/Recurso 2@4x.png';
import CONFIG from '../Config.js';

const ImageWithFallback = ({ src, alt, className }) => {
  const [imgSrc, setImgSrc] = useState(src);
  
  useEffect(() => {
    setImgSrc(src);
  }, [src]);
  
  const handleError = () => {
    if (imgSrc.endsWith('.png')) {
      setImgSrc(imgSrc.replace('.png', '.jpg'));
    }
  };
  
  return <img src={imgSrc} alt={alt} className={className} onError={handleError} />;
};

const generateGroupImages = (groupName, stage) => {
  const formattedGroupName = groupName
    .replace(/^U(\d+)/, 'SUB$1')
    .replace(/ /g, '_');
    
  const formattedStage = stage.toLowerCase();
  const SERVER_BASE_URL = CONFIG.SERVER_BASE_URL;

  const getImage = (path) => {
    return `${SERVER_BASE_URL}/${stage}/${formattedGroupName}/${path}.png`;
  };

  const getImageArray = (baseName, count) => {
    return Array(count).fill().map((_, i) => getImage(`${baseName}_${i + 1}`));
  };

  return {
    calendarioResultados: getImageArray('calendario_resultado', 6),
    clasificacionRendimientos: stage === 'apertura' ? getImageArray('clasificacion_rendimientos', 3) : [],
    clasificacionRendimientosDesktop: stage === 'apertura' ? getImage('clasificacion_rendimientos_desktop') : null,
    clasificacionRendimientosMobile: stage === 'apertura' ? getImage('clasificacion_rendimientos_mobile') : null,
    clasificacionDesktop: getImage('clasificacion_desktop'),
    clasificacionMobile: getImage('clasificacion_mobile'),
    team: getImageArray('team', 7),
    finalDesktop: getImage('final_desktop'),
    finalMobile: getImage('final_mobile')
  };
};

const validGroups = [
  'SUB19 Grupo 1',
  'SUB19 Grupo 2',
  'SUB16 Grupo 1',
  'SUB16 Grupo 2',
  'SUB19 Grupo 3',
  'SUB19 Grupo 4',
  'Femenino',
  'SUB19 Grupo 5',
  'SUB19 Grupo 6',
  'SUB16 Grupo 3',
  'SUB16 Grupo 4',
];

const CLAUSURA_ONLY_GROUPS = [
  'SUB19 Grupo 5',
  'SUB19 Grupo 6',
  'SUB16 Grupo 3',
  'SUB16 Grupo 4',
];

const groupTeams = {
  apertura: {
    "SUB19 Grupo 1": ["Powerade","C.E. Poble Sec", "Premier", "Congress", "Singuerlin", "Sant Andreu", "C.E. Europa"],
    "SUB19 Grupo 2": ["Aquarius", "P.B. Anguera", "AT Sant Just", "I. Americano", "FAF Hospitalet", "Joves Sabadell", "Sant Andreu"],
    "SUB16 Grupo 1": ["Powerade","AT Sant Just", "Singuerlin", "Mirasol", "Sant Andreu", "Fredrikstad", "N. Tarragona"],
    "SUB16 Grupo 2": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
    "SUB19 Grupo 3": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
    "SUB19 Grupo 4": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
    "SUB14 Grupo 1": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
    "Femenino": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"]
  },
  meridiano: {
    "SUB19 Grupo 1": ["Powerade S19", "AC Joves VO", "Premier", "CE Hospitalet", "Sant Just", "Sant Andreu", "C.E. Europa"],
    "SUB19 Grupo 2": ["Aquarius S19", "C.E. Europa", "CE Hospitalet", "Premier", "Mercantil CE", "Sant Just", "Sant Andreu"],
    "SUB16 Grupo 1": ["Powerade S16", "Sant Just", "CE Hospitalet", "CE Europa", "Sant Andreu A", "UE SA Club Z", "Premier"],
    "SUB16 Grupo 2": ["Aquarius S16", "Sant Just", "CE Hospitalet", "FAF Hospitalet", "Premier", "Mercantil", "C.E. Europa"],
    "SUB19 Grupo 3": ["Cocacola S19", "Sant Andreu", "Mercantil", "CE Hospitalet", "Veterans", "FAF Hospitalet", "C.E. Europa"],
    "SUB19 Grupo 4": ["Sprite S19", "Sant Andreu", "Premier", "CE Hospitalet", "Sant Just", "Singuerlin", "C.E. Europa"],
    "SUB14 Grupo 1": ["Powerade S14", "Sant Andreu", "Premier", "CE Hospitalet", "Mira-Sol", "FAF Hospitalet", "C.E. Europa"],
    "Femenino": ["C.E. Europa", "Sant Cugat", "Levante BDN A", "Fontsanta A", "Mercantil", "Levante BDN B", "Fontsanta B"]
  },  
  clausura: {
    "SUB19 Grupo 1": [
      "POWERADE Manigua",
      "APA Poble Sec",
      "UE Sant Andreu",
      "AE Prat",
      "Atletic Sant Just FC",
      "Fundacio Esportiva Grama",
      "CE Europa"
    ],
    "SUB19 Grupo 2": [
      "AQUARIUS MANIGUA",
      "Fundacio Esportiva Grama",
      "CE Europa",
      "Congres Club Esp",
      "Pª Barc Anguera",
      "UE Sant Andreu",
      "APA Poble SEC"
    ],
    "SUB19 Grupo 3": [
      "SPRITE ESTE",
      "Fundacio Esportiva Grama",
      "APA Poble Sec",
      "CE Europa",
      "CE L'Hospitalet",
      "Atletic Sant Just FC",
      "UE Sants"
    ],
    "SUB19 Grupo 4": [
      "COCA COLA ESTE",
      "Veterans Catalunya AF",
      "CE L'Hospitalet",
      "Pª Barc Anguera",
      "Fundacio Esportiva Grama",
      "CE Europa",
      "UE Sant Andreu"
    ],
    "SUB19 Grupo 5": [
      "SPRITE NORTE",
      "LA SALUT PERE GOL A",
      "ESCOLA DE FUTBOL PREMIER BARCELONA D",
      "SANTA ANDREU DE LA BARCA AGRUP A",
      "Premia Club ESP",
      "CF Can Vidalet"
    ],
    "SUB19 Grupo 6": [
      "COCA COLA NORTE",
      "CF CAN VIDALET D",
      "ESCOLA FUTBOLVALLVIDRERA A",
      "UE SANTS D",
      "ESCOLA FUTBOL ALMOGAVERS A",
      "CF BARCELONETA B"
    ],
    "Femenino": [
      "EUROPA MARCET",
      "LEVANTE LP BADALONA FC C",
      "FONTSANTA-FATJO CD B",
      "CE MERCANTIL A",
      "FONTSANTA-FATJO CD A",
      "LEVANTE LP BADALONA FC B"
    ],
    "SUB16 Grupo 1": [
      "POWERADE FAF",
      "CE EUROPA",
      "ATLETIC SANT JUST FC",
      "APA POBLE SEC",
      "UE SANT ANDREU",
      "FUNDACIO ESPORTIVA GRAMA",
      "Ce Hospitalet"
    ],
    "SUB16 Grupo 2": [
      "AQUARIUS VETERANS",
      "ATLETIC SANT JUST FC",
      "CE L'HOSPITALET",
      "Pª BARC ANGUERA",
      "CE EUROPA",
      "APA POBLE SEC",
      "FE Grama"
    ],
    "SUB16 Grupo 3": [
      "SPRITE",
      "UE SANTS B",
      "CF CAN VIDALET A",
      "MIRASOL BACO UNION CF C",
      "CE OLIMPIC CAN FATJO A",
      "CONGRES CLUB ESP C",
      "Racing Sarria"
    ],
    "SUB16 Grupo 4": [
      "COCA COLA",
      "ESCOLA FUTBOL VALLVIDRERA A",
      "SANT ANDREU DE LA BARCA AGRUP A",
      "UE SANTS D",
      "LA SALUT PERE GOL A",
      "CF CAN VIDALET A",
      "Almogavers"
    ]
  }  
};


const getTeamNames = (groupName, stage) => {
  return groupTeams[stage]?.[groupName] || [];
};

const SPECIAL_TEAMS = ['SUB19 Grupo 3', 'SUB19 Grupo 4', 'SUB14 Grupo 1','Femenino'];

const HyperLeague = () => {
  const { group } = useParams();
  const normalizedGroup = group.replace(/-/g, ' ');
  const isSpecialTeam = SPECIAL_TEAMS.includes(normalizedGroup);
  const isClausuraOnlyGroup = CLAUSURA_ONLY_GROUPS.includes(normalizedGroup);
  
  // Establecer la pestaña activa según el tipo de grupo
  const getInitialActiveTab = () => {
    if (isClausuraOnlyGroup) {
      return 'clausura';
    } else if (isSpecialTeam) {
      return 'meridiano';
    }
    return 'apertura';
  };
  
  const [activeTab, setActiveTab] = useState(getInitialActiveTab());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [groupImages, setGroupImages] = useState(null);

  // Actualiza la pestaña activa cuando cambia el grupo
  useEffect(() => {
    if (isClausuraOnlyGroup) {
      setActiveTab('clausura');
    } else if (isSpecialTeam && activeTab === 'apertura') {
      setActiveTab('meridiano');
    }
  }, [normalizedGroup, isSpecialTeam, isClausuraOnlyGroup]);

  useEffect(() => {
    const images = generateGroupImages(normalizedGroup, activeTab);
    setGroupImages(images);
  }, [normalizedGroup, activeTab]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!validGroups.includes(normalizedGroup)) {
    return <Navigate to="/404" />;
  }

  const handleTabChange = (tab) => {
    if (isClausuraOnlyGroup) {
      if (tab === 'clausura') {
        setActiveTab(tab);
      }
    } else if (isSpecialTeam) {
      if (tab !== 'apertura') {
        setActiveTab(tab);
      }
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className="home-container">
      <Navbar />
      <main className="league-content">
        <section className="hero-league-section">
          <div className="hero-league-content">
            <div className="hero-league-text">
              <h1>{normalizedGroup}</h1>
            </div>
          </div>
        </section>

        <div className="tab-navigation content-wrapper">
          <button 
            className={`tab-button ${activeTab === 'apertura' ? 'active' : ''} ${isSpecialTeam || isClausuraOnlyGroup ? 'disabled' : ''}`} 
            onClick={() => handleTabChange('apertura')}
            disabled={isSpecialTeam || isClausuraOnlyGroup}
          >
            APERTURA
          </button>
          <button 
            className={`tab-button ${activeTab === 'meridiano' ? 'active' : ''} ${isClausuraOnlyGroup ? 'disabled' : ''}`} 
            onClick={() => handleTabChange('meridiano')}
            disabled={isClausuraOnlyGroup}
          >
            MERIDIANO
          </button>
          <button 
            className={`tab-button ${activeTab === 'clausura' ? 'active' : ''}`} 
            onClick={() => handleTabChange('clausura')}
          >
            CLAUSURA
          </button>
        </div>

        <section className="tab-content content-wrapper">
          {activeTab === 'apertura' && (
            <div>
              <h1 className="section-title">EQUIPOS</h1>
              <div className="teams-container">
              {groupImages?.team?.map((src, index) => (
                <div key={index} className="team-item">
                  <div className="team-icon-wrapper">
                    <ImageWithFallback 
                      src={src} 
                      alt={`Equipo ${index + 1}`} 
                      className="teams-icon"
                    />
                  </div>
                  <p className="team-label">
                    {getTeamNames(normalizedGroup, activeTab)[index] || `Equipo ${index + 1}`}
                  </p>
                </div>
              ))}
            </div>
              <div className="logo-position">
                <img src={Logo} alt="Logo" className="logo-image" />
              </div>
              <h1 className="section-title">CALENDARIOS Y RESULTADOS</h1>
              <div className="calendar-grid">
                {groupImages?.calendarioResultados.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <ImageWithFallback 
                        src={src} 
                        alt={`Calendario resultado ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>

              <h1 className="section-title">CLASIFICACIÓN</h1>
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionMobile && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionMobile} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionDesktop && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionDesktop} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                )}
              </div>

              <h1 className="section-title">
                CLASIFICACIÓN POR DESEMPEÑOS
                <p className="centered-text">Defensa + Construcción + Ataque</p>
              </h1>
              
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionRendimientosMobile && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionRendimientosMobile} 
                      alt="clasificación por desempeños" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionRendimientosDesktop && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionRendimientosDesktop} 
                      alt="clasificación por desempeños" 
                      className="league-image" 
                    />
                  )
                )}
              </div>

              <div className="calendar-grid">
                {groupImages?.clasificacionRendimientos.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <ImageWithFallback 
                        src={src} 
                        alt={`Clasificación desempeños ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeTab === 'meridiano' && (
            <div>
              <h1 className="section-title">EQUIPOS</h1>
              <div className="teams-container">
                {groupImages?.team?.map((src, index) => (
                  <div key={index} className="team-item">
                    <div className="team-icon-wrapper">
                      <ImageWithFallback 
                        src={src} 
                        alt={`Equipo ${index + 1}`} 
                        className="teams-icon"
                      />
                    </div>
                    <p className="team-label">
                      {getTeamNames(normalizedGroup, activeTab)[index] || `Equipo ${index + 1}`}
                    </p>
                  </div>
                ))}
              </div>
              <div className="logo-position">
                <img src={Logo} alt="Logo" className="logo-image" />
              </div>

              <h1 className="section-title">FINAL</h1>
                <div className="league-image-container">
                  {isMobile ? (
                    groupImages?.finalMobile && (
                      <ImageWithFallback 
                        src={groupImages.finalMobile} 
                        alt="Final" 
                        className="league-image" 
                      />
                    )
                  ) : (
                    groupImages?.finalDesktop && (
                      <ImageWithFallback 
                        src={groupImages.finalDesktop} 
                        alt="Final" 
                        className="league-image" 
                      />
                    )
                  )}
                </div>

              <h1 className="section-title">CALENDARIOS Y RESULTADOS</h1>
              <div className="calendar-grid">
                {groupImages?.calendarioResultados.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <ImageWithFallback 
                        src={src} 
                        alt={`Calendario resultado ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>

              <h1 className="section-title">CLASIFICACIÓN</h1>
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionMobile && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionMobile} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionDesktop && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionDesktop} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                )}
              </div>
              
            </div>
          )}
          {activeTab === 'clausura' && (
            <div>
              <h1 className="section-title">EQUIPOS</h1>
              <div className="teams-container">
                {groupImages?.team?.map((src, index) => (
                  <div key={index} className="team-item">
                    <div className="team-icon-wrapper">
                      <ImageWithFallback 
                        src={src} 
                        alt={`Equipo ${index + 1}`} 
                        className="teams-icon"
                      />
                    </div>
                    <p className="team-label">
                      {getTeamNames(normalizedGroup, activeTab)[index] || `Equipo ${index + 1}`}
                    </p>
                  </div>
                ))}
              </div>
              <div className="logo-position">
                <img src={Logo} alt="Logo" className="logo-image" />
              </div>

              <h1 className="section-title">CALENDARIOS Y RESULTADOS</h1>
              <div className="calendar-grid">
                {groupImages?.calendarioResultados.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <ImageWithFallback 
                        src={src} 
                        alt={`Calendario resultado ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>

              <h1 className="section-title">CLASIFICACIÓN</h1>
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionMobile && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionMobile} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionDesktop && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionDesktop} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                )}
              </div>
            </div>
          )}

        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HyperLeague;