import React, { useState, useEffect, useRef } from 'react';
import '../css/Navbar.css';
import { Link } from 'react-router-dom';
import hyperleagueLogo from '../img/Hyperleague logo.png';
import { useTranslation } from 'react-i18next';
import 'flag-icon-css/css/flag-icons.css';
import Select from 'react-select';

const languageOptions = [
  { value: 'ESP', label: 'ESPAÑOL', icon: 'flag-icon flag-icon-es' },
  { value: 'CAT', label: 'CATALÀ', icon: 'flag-icon flag-icon-es-ct' },
];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('ESP');
  const dropdownRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      setIsDropdownOpen(true);
    }
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (selectedOption) => {
    const selectedLanguage = selectedOption.value;
    setLanguage(selectedLanguage);

    if (selectedLanguage === 'ESP') {
      i18n.changeLanguage('es');
    } else if (selectedLanguage === 'CAT') {
      i18n.changeLanguage('ca');
    }
  };

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'unset';
  }, [isMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownItemClick = () => {
    setIsDropdownOpen(false);
    setIsMenuOpen(false);
  };

  const formatOptionLabel = ({ label, icon }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span className={icon} style={{ marginRight: 8 }}></span>
      {label}
    </div>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid d-flex align-items-center" style={{maxWidth: "1200px"}}>
        <Link className="navbar-brand" to="/" onClick={() => setIsMenuOpen(false)}>
          <img src={hyperleagueLogo} alt="Logo" className="left-logo" />
        </Link>
        <div className={`nav-toggle ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
          <div className="bar"></div>
        </div>
        <div className={`navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
          <hr className="navbar-divider" />
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link fw-bold" to="/" onClick={() => setIsMenuOpen(false)}>HOME</Link>
            </li>
            <li className="nav-item dropdown" ref={dropdownRef}>
              <a 
                className="nav-link dropdown-toggle fw-bold" 
                href="#" 
                id="navbarDropdown" 
                role="button" 
                onClick={handleDropdownToggle}
              >
                HYPERLEAGUES
              </a>
              <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/hypearleague/SUB19-Grupo-1" onClick={handleDropdownItemClick}>SUB19 Grupo 1</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB19-Grupo-2" onClick={handleDropdownItemClick}>SUB19 Grupo 2</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB19-Grupo-3" onClick={handleDropdownItemClick}>SUB19 Grupo 3</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB19-Grupo-4" onClick={handleDropdownItemClick}>SUB19 Grupo 4</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB19-Grupo-5" onClick={handleDropdownItemClick}>SUB19 Grupo 5</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB19-Grupo-6" onClick={handleDropdownItemClick}>SUB19 Grupo 6</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB16-Grupo-1" onClick={handleDropdownItemClick}>SUB16 Grupo 1</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB16-Grupo-2" onClick={handleDropdownItemClick}>SUB16 Grupo 2</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB16-Grupo-3" onClick={handleDropdownItemClick}>SUB16 Grupo 3</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/SUB16-Grupo-4" onClick={handleDropdownItemClick}>SUB16 Grupo 4</Link></li>
                <li><Link className="dropdown-item" to="/hypearleague/Femenino" onClick={handleDropdownItemClick}>Femenino</Link></li>
              </ul>
            </li>
            <hr className="navbar-divider" />
            {/* <li className="nav-item">
              <Select
                options={languageOptions}
                onChange={handleLanguageChange}
                value={languageOptions.find(option => option.value === language)}
                className="language-selector"
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
              />
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;